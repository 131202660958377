import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { configuration } from 'src/configurations';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class NotificationService {
  constructor(
    private matSnackBar: MatSnackBar,
  ) { }

  public error(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.matSnackBar.open(message, 'OK', { ...configuration.notifications.defaultConfig } as MatSnackBarConfig);
  }

  public openFromComponent<T>(component: ComponentType<T>, config?: MatSnackBarConfig): MatSnackBarRef<T> {
    const fullConfig = { ...configuration.notifications.defaultConfig, ...config } as MatSnackBarConfig;

    return this.matSnackBar.openFromComponent<T>(component, fullConfig);
  }
}
